import React, { useState, useEffect } from "react";
import Logo from "./../assets/DA-Logo.png";
import { useWeb3Modal } from '@web3modal/react';
import { useAccount } from 'wagmi'
function Header() {
    const { isOpen, open, close } = useWeb3Modal();
    const [walletAddress, setwalletAddress] = useState();
    const { address } = useAccount()
 
    const [showMenu, setShowMenu] = useState(false);
    const [menuLink, setMenuLink] = useState("/");


    useEffect(() => {
        async function checkHeader() {
            const URL = window.location.pathname
            if(URL?.includes("lpstaking")){
                setShowMenu(true)
                setMenuLink("/lpstaking")
            }
            if(URL?.includes("claim")){
                setMenuLink("/claim")
            }
        }
        checkHeader();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {
        async function checkConnection() {
            if (address) {
                var firstFive = address.slice(0, 6);
                var lastFive = address.slice(address.length - 6, address.length);
                let userAddress = firstFive+"..."+lastFive;
                setwalletAddress(userAddress);
            }
        }
        checkConnection();
    }, [address]);

    const handleConnect = async () => {
        if (isOpen) {
          await close();
        } else {
          await open();
        }
    };

    return (
        <>
            <header className="cs-site_header cs-style1">
                <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                    <div className="container">
                        <a className="navbar-brand" href={menuLink}>
                            <img src={Logo} className="h-8" alt="Hunter Logo"/>
                        </a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse pull-right" id="navbarCollapse">
                            { showMenu ? (<>
                                <ul className="mr-auto navbar-nav margin-right">
                                    <li className="home-menu nav-item">
                                        <a className="nav-link" href="/lpstaking"> Home</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/lpstaking/pool"> Menu</a>
                                    </li>
                                </ul>
                                <div className="d-flex align-items-lg-center mt-3 mt-lg-0"></div>
                            </>) : <></> }
                            <div className='text-White'>
                                { address? (
                                    <button className='connected-to-address'> 
                                        <h6 className='connected-to-heading'>Connected to</h6>
                                        <p className='mb-0'>{ walletAddress  }</p>
                                    </button>
                                ) : ('') } 
                            </div>
                            <button className="wallet_connect_Login" onClick={() => handleConnect() }>{address ? 'Disconnect' : 'Connect'}</button> 
                        </div>
                    </div>
                </nav>
            </header>
            <div className="cs-height_80 cs-height_lg_80"></div>
        </>
    );
}
export default Header;