import axios from "axios";
import { CONFIG } from "./config.js";
let api = {};
var BASE_URL = CONFIG.BASE_URL;

let getDefaultHeaders = () => {
    return {
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
            "Access-Control-Allow-Credentials": true,
        },
    };
};

api.getDefaultHeaders = getDefaultHeaders;

api.showLoader = () => {  };

api.hideLoader = () => { };

api.get = (url) => {
    api.showLoader();
    let headers = getDefaultHeaders();
    return new Promise(function (resolve, reject) {
        axios.get(`${BASE_URL}/${url}`, headers).then((response) => {
            resolve(response);
            setTimeout(function () { api.hideLoader(); }, 300);
        })
        .catch((error) => {
            console.log(error);
        });
    });
};
export default api;
