import React, { useState, useEffect, useRef } from "react";
import { useAccount, useNetwork } from 'wagmi'
import Web3 from 'web3';
import hunterlogo from './../assets/hunter-logo.png';
import Web3provider from './../Helpers/Web3Provider';
import PoolPopup from './poolPopUp';
import { CONFIG } from "../utils/config";
import SweetAlert from 'react-bootstrap-sweetalert';
import Loading from "../includes/loader";

function LPStakingPool() {
    const Web3Handler = new Web3provider();
    const { address } = useAccount()
    const { chain } = useNetwork()
    const hunterPancakeURL = CONFIG.HUNTER_PANCAKE;
    const [loading, setLoading] = useState(false);
    const [alertPopUp, setalertPopUp] = useState(false);
    let [setWithdraw] = useState("");
    const [isPoolPopup, setIsPoolPopup] = useState(false);
    let [withdrawHntrUSD, SetwithdrawHntrUSD] = useState("");
    let [setDeposit] = useState("");
    let [depositHntrUSD, SetdepositHntrUSD] = useState("");
    const [isPoolPopup1, setIsPoolPopup1] = useState(false);
    const [withdrawAmt, setwithdrawAmt] = useState(0);
    const [depositAmt, setdepositAmt] = useState(0);
    const [TotaldepositAmt, setTotaldepositAmt] = useState(0);
    const [earnedRewardAmt, setearnedRewardAmt] = useState(0);
    const [myWithdrawNumber, setWithdrawNumber] = useState(0);
    const [myDepositNumber, setmyDepositNumber] = useState(0);
    const maxWidtrawlAmt = useRef(withdrawAmt);
    const maxDepositAmt = useRef(depositAmt);
  
    const [depositLpdisable, setdepositLpdisable] = useState(true);
    const [widthrawLpdisable, setwidthrawLpdisable] = useState(true);
    const [ selectedChain, setSelectedChain] = useState(false);
    
    setWithdraw = () => {
        maxWidtrawlAmt.current.value = withdrawAmt;
        SetwithdrawHntrUSD(withdrawAmt);
        setWithdrawNumber(withdrawAmt);
    };
    
    setDeposit = () => {
        maxDepositAmt.current.value = depositAmt;
        if (depositAmt > 0) {
            SetdepositHntrUSD(depositAmt);
        }
        if(depositAmt === "0." || depositAmt === 0.){
            setmyDepositNumber(0);
        }else{
            setmyDepositNumber(depositAmt);
        }
    };
    
    const togglePoolPopup = () => {
        setIsPoolPopup(!isPoolPopup);
        SetwithdrawHntrUSD(0);
        setWithdrawNumber(0);
    }
    
    const togglePoolPopup1 = () => {
        setIsPoolPopup1(!isPoolPopup1);
        SetdepositHntrUSD(0);
        setmyDepositNumber(0);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        async function fetchData() {
            if (!address) {
                setTotaldepositAmt(0);
                setwithdrawAmt(0);
                SetwithdrawHntrUSD(0);
                setearnedRewardAmt(0);
            }
            if(address){
                checkAllowanceTokenFunction();
                getDepositeLpTokenBalanceFunction();
                getDepositeBalanceFunction();
                getEarningBalanceFunction();
                checkIshasApprovalLPToken();
            }
            setSelectedChain(chain?.id)
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address, chain]);
    
    const MINUTE_MS = 10000;
    
    useEffect(() => {
        const interval = setInterval(() => {
            if (address) {
                getEarningBalanceFunction();
            }
            setSelectedChain(chain?.id)
        }, MINUTE_MS);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address, chain])


    function checkAllowanceTokenFunction() {
        if (address) {
            Web3Handler.checkAllowanceToken(address).then(res => {
                let allowedAmt = Web3.utils.toWei(Web3.utils.toNumber(res.toString()), 'ether');
                if (allowedAmt > 0) {
                    setdepositLpdisable(false);
                } else {
                    setdepositLpdisable(true);
                    setwidthrawLpdisable(true);
                }
            }).catch(err => {
                setdepositLpdisable(true);
                setwidthrawLpdisable(true);
            });
        }
      }
    
    function getDepositeLpTokenBalanceFunction() {
        if (address) {
            Web3Handler.getBalanceLPToken(address).then(res => {
                if (res === 0 || res === "0") {
                    setdepositLpdisable(true);
                } else {
                    setdepositLpdisable(true);
                }
                let balance = Web3.utils.fromWei(res, 'ether');
                if(balance === "0." || balance === 0.){
                    balance = 0;
                }
                setdepositAmt(balance);
            }).catch(err => {
                console.log("error in getDepositeLpTokenBalanceFunction", err)
            });
        } else {
            setdepositAmt(0);
            SetdepositHntrUSD(0);
        }
    }

    async function getDepositeBalanceFunction() {
        if (address) {
            Web3Handler.getdepositeBalanceLPToken(address).then(res => {
                let balance = Web3.utils.fromWei(res[0], 'ether');
                if(balance === "0." || balance === 0.){
                    balance = 0;
                }
                let displayBalance = balance;
                if (displayBalance % 1 !== 0) {
                    displayBalance = parseFloat(displayBalance).toFixed(5);
                }
                setTotaldepositAmt(displayBalance);
                setwithdrawAmt(balance);
            }).catch(err => {
                
            });
        } else {
            setTotaldepositAmt(0);
            setwithdrawAmt(0);
        }
    }

    function checkIshasApprovalLPToken() {
        if (address) {
            Web3Handler.getBalanceLPToken(address).then(res => {
                if (res === 0 || res === "0") {
                    setdepositLpdisable(true);
                } else {
                    setdepositLpdisable(true);
                    Web3Handler.checkApprovalLPToken(address).then(allowance => {
                        if (allowance >= res) {
                            setdepositLpdisable(false);
                        } else {
                            setdepositLpdisable(true);
                        }
                    });
                }
            })
        }
    }
    
    function getEarningBalanceFunction() {
        if (address) {
            Web3Handler.getEarningBalanceRewardsToken(address).then(res => {
                if (res > 0) {
                    setwidthrawLpdisable(false);
                } else {
                    setwidthrawLpdisable(true);
                }
                let balance = Web3.utils.fromWei(res, 'ether');
                if(balance === "0." || balance === 0. ){
                    balance = 0;
                }
                if (balance % 1 !== 0) {
                    balance = parseFloat(balance).toFixed(5);
                }
                setearnedRewardAmt(balance);
            }).catch(err => {
                
            });
        } else {
            setearnedRewardAmt(0);
        }
    }
    
    async function approveLpTokenFunction() {
        if(address){
            setLoading(true);
            Web3Handler.checkSelectedChain(selectedChain).then(chainRes => {
                if(chainRes){
                    Web3Handler.getBalanceLPToken(address).then(res => {
                        let userBalance = Web3.utils.toWei(Web3.utils.toNumber(res.toString()), 'ether')
                        if (userBalance === 0 || userBalance === "0") {
                            setLoading(false)
                            setalertPopUp(funErrorPopupNoBalance("Account Balance is Zero"));
                        } else {
                            let postData = { balance: userBalance };
                            Web3Handler.getApprovalLPToken(postData).then(res1 => {
                                setLoading(false)
                                setdepositLpdisable(false);
                            }).catch(err1 => {
                                setLoading(false)
                                setalertPopUp(funErrorPopup(err1.message));
                            });
                        }
                    }).catch(err => {
                        setLoading(false)
                        console.log("err", err)
                        setalertPopUp(funErrorPopup("Something went wrong. Please try again Later"));
                    });
                }else{
                    setLoading(false)
                    setalertPopUp( funErrorPopup("You are on different Chain", "Please switch to BSC."));
                }
            }).catch(err => {
                setLoading(false)
                setalertPopUp( funErrorPopup("You are on different Chain", "Please switch to BSC."));
            });
        }
    }
    
    async function depositLpTokenFunction() {
        if(address){
            setLoading(true)
            Web3Handler.checkSelectedChain(selectedChain).then(chainRes => {
                if(chainRes){
                    let depositepostData = { amount: depositHntrUSD };
                    Web3Handler.checkAllowanceToken(address).then(allowance => {
                        let AllowanceAmt = allowance;
                        Web3Handler.getBalanceLPToken(address).then(balance => {
                            if (parseInt(balance) > parseInt(AllowanceAmt)) {
                                let allowanceAmt = Web3.utils.toWei(Web3.utils.toNumber(balance.toString()), 'ether');
                                let postData = { balance: allowanceAmt };
                                Web3Handler.getApprovalLPToken(postData).then(res1 => {
                                    console.log("depositLpTokenFunction getApprovalLPToken", res1)
                                    Web3Handler.depositLpToken(depositepostData).then(dep_res1 => {
                                        console.log("depositLpTokenFunction depositLpToken", dep_res1)
                                        setLoading(false)
                                        setalertPopUp(funSuccessPopup("Token(s) Deposited Successfully", ""));
                                    }).catch(err1 => {
                                        console.log("err1 fgd", err1)
                                        setLoading(false)
                                        setalertPopUp(funErrorPopup("Something went wrong. Please try again Later"));
                                    });
                                }).catch(err1 => {
                                    console.log("err1 dfgsdf", err1)
                                    setLoading(false)
                                    setalertPopUp(funErrorPopup("Something went wrong. Please try again Later"));
                                });
                            } else {
                                Web3Handler.depositLpToken(depositepostData).then(dep_res => {
                                    console.log("depositLpTokenFunction depositLpToken", dep_res)
                                    setLoading(false)
                                    setalertPopUp(funSuccessPopup("Token(s) Deposited Successfully", ""));
                                }).catch(err1 => {
                                    console.log("err1 34564", err1)
                                    setLoading(false)
                                    setalertPopUp(funErrorPopup("Something went wrong. Please try again Later"));
                                });
                            }
                        }).catch(err => {
                            console.log("err1 564654", err)
                            setLoading(false)
                            setalertPopUp(funErrorPopup("Something went wrong. Please try again Later"));
                        });
                    }).catch(err => {
                        setLoading(false)
                        setalertPopUp(funErrorPopup("Something went wrong. Please try again Later"));
                    });
                }else{
                  setLoading(false)
                  setalertPopUp( funErrorPopup("You are on different Chain", "Please switch to BSC."));
                }
            }).catch(err => {
                setLoading(false)
                setalertPopUp( funErrorPopup("You are on different Chain", "Please switch to BSC."));
            });
        }else{
            setLoading(false)
            setalertPopUp(funErrorPopup("Something went wrong. Please try again Later"));
        }   
    }
    
    async function withdrawLpTokenFunction() {
        if(address){
            setLoading(true)
            Web3Handler.checkSelectedChain(selectedChain).then(chainRes => {
                if(chainRes){
                    if (withdrawHntrUSD === "" || withdrawHntrUSD === undefined || withdrawHntrUSD === 0) {
                        setLoading(false)
                        setalertPopUp(funErrorPopup("Please enter withdrawl amount"));
                    } else {
                        let withdrawlBalance = withdrawHntrUSD;
                        if (withdrawlBalance % 1 !== 0) {
                            withdrawlBalance = parseFloat(withdrawlBalance).toFixed(5);
                        }
                        if (Number(withdrawlBalance) > Number(TotaldepositAmt)) {
                            setLoading(false)
                            setalertPopUp(funErrorPopup("Withdrawl Amount is greater than available Amount"));
                        } else {
                            let postData = { amount: myWithdrawNumber };
                            Web3Handler.withdrawLpToken(postData).then(dep_res => {
                                console.log("dep_res ", dep_res)
                                setLoading(false)
                                setalertPopUp(funSuccessPopup("Token(s) Withdrawn Successfully", ""));
                            }).catch(err1 => {
                                setLoading(false)
                                setalertPopUp(funErrorPopup(err1?.errorMessage, ""));
                            });
                        }
                    }
                }else{
                  setLoading(false)
                  setalertPopUp( funErrorPopup("You are on different Chain", "Please switch to BSC."));
                }
            }).catch(err => {
                setLoading(false)
                setalertPopUp( funErrorPopup("You are on different Chain", "Please switch to BSC."));
            });
        }else{
            setLoading(false)
            setalertPopUp(funErrorPopup("Something went wrong. Please try again Later"));
        }  
    }
    
    async function withdrawRewardsFunction() {
        if(address){
            setLoading(true)
            Web3Handler.checkSelectedChain(selectedChain).then(chainRes => {
                if(chainRes){
                    let withdrawHntrUSDN = (Number(0));
                    let postData = { amount: withdrawHntrUSDN };
                    Web3Handler.withdrawLpToken(postData).then(dep_res => {
                        setLoading(false)
                        setalertPopUp(funSuccessPopup("Rewards Withdrawn Successfully.", ""));
                    }).catch(error => {
                        setLoading(false)
                        if(error?.code === 4001){
                            setalertPopUp(funErrorPopup(error.message));
                        }else{
                            setalertPopUp(funErrorPopup("Something went wrong. Please try again Later"));
                        }
                    });
                }else{
                  setLoading(false)
                  setalertPopUp( funErrorPopup("You are on different Chain", "Please switch to BSC."));
                }
            }).catch(err => {
                setLoading(false)
                setalertPopUp( funErrorPopup("You are on different Chain", "Please switch to BSC."));
            });
        }else{
            setLoading(false)
            setalertPopUp(funErrorPopup("Something went wrong. Please try again Later"));
        }   
    }
    
    function funSuccessPopup(msg, msgnexline = "") {
        return (<SweetAlert title="Success" onConfirm={() => funhideSuccessPopup()}> {msg}<br></br>{msgnexline} </SweetAlert>);
    }
    
    function funErrorPopup(msg, msgnexline = "") {
        let errrorMsg = msg;
        if (msg === " withdraw: not good") {
            errrorMsg = "Withdrawal Failed";
        }
        return (<SweetAlert title="Error" onConfirm={() => funhideErrorFailure()}>{errrorMsg}<br></br>{msgnexline}</SweetAlert>);
    }
    
    function funErrorPopupNoBalance(msg) {
        return (<SweetAlert title="Warning" onConfirm={() => funhideErrorFailure()}>{msg}<br></br><br></br><div className="card-container"><a href={hunterPancakeURL} rel="noopener noreferrer" target="_blank" className="token-btn pancakelink alertBox">Get HNTR-BUSD-LP Tokens</a></div></SweetAlert>);
    }
    
    function funhideSuccessPopup() {
        setalertPopUp("");
        window.location.reload(false);
    }
    
    function funhideErrorFailure() {
        setalertPopUp("");
    }
    
    function handleChangeWithdrawl(event) {
        const re = /^\d*\.?\d*$/;
        let val = event.target.value;
        if (event.target.value === "" || re.test(event.target.value)) {
            const numStr = String(val);
            if (numStr.includes(".")) {
                if (numStr.split(".")[1].length > 18) {
                    return
                }
            } else {
                val = Number(val);
            }
            let withdrawAmtlength = withdrawAmt.length;
            let vallength = val?.length ? val.length : 0;
            if (withdrawAmtlength >= vallength) {
                if (Number(val) <= withdrawAmt) {
                    setWithdrawNumber(val);
                    let w_number = Number(val);
                    SetwithdrawHntrUSD(w_number);
                }
            }
        }
    }
    
    function handleChangeDeposit(event) {
        const re = /^\d*\.?\d*$/;
        let val = event.target.value;
        if (event.target.value === "" || re.test(event.target.value)) {
            const numStr = String(val);
            if (numStr.includes(".")) {
                if (numStr.split(".")[1].length > 18) {
                    return
                }
            } else {
                val = Number(val);
            }
            let depositAmtlength = depositAmt.length;
            let vallength = val?.length ? val.length : 0;
            if (depositAmtlength >= vallength) {
                if (Number(val) <= depositAmt) {
                    setmyDepositNumber(val);
                    let w_number = Number(val);
                    SetdepositHntrUSD(w_number);
                }
            }
        }
    }
    
    return (<>
        <div className='container'>
            <div className='stakingpool-content-div staking-content-div'>
                <div className='hntr-boxed pool_section'>
                    <div className="content-h2 mt-4">
                        <img src={hunterlogo} className="hunter-Logo-img" alt="" />
                    </div>
                    <p className="content-p mb-4">Deposit HNTR-BUSD-LP Tokens and Earn HNTR</p>
                    <div>
                        <div className='card-container pool_container'>
                            <div className="staking_pool_group card-group">
                                <div className='card pool1-card pool_custom_box'>
                                    <div className='pools-card card-body'>
                                        <div className='card-icon'>
                                            <img src={hunterlogo} className="hunter-Logo-img" alt="" />
                                        </div>
                                        <h5 className="card-title"> <span className="pricecolor">{earnedRewardAmt}</span> </h5>
                                        <p className="card-text"> HNTR Earned </p>
                                    </div>
                                    {address ? (
                                        <div className='card-bottom text-center mb-4'>
                                            <button onClick={togglePoolPopup} className='btn-DA-theme approve_deposit_btn approve-lp m-1'> Unstake </button>
                                            <button onClick={withdrawRewardsFunction} className='btn-DA-theme approve_deposit_btn approve-lp m-1'> Claim Rewards </button>
                                            {/* <button onClick={togglePoolPopup} disabled={widthrawLpdisable} className='btn-DA-theme approve_deposit_btn approve-lp m-1'> Unstake </button>
                                            <button onClick={withdrawRewardsFunction} disabled={widthrawLpdisable} className='btn-DA-theme approve_deposit_btn approve-lp m-1'> Claim Rewards </button> */}
                                            {isPoolPopup && <PoolPopup content={<>
                                                <div className='row'>
                                                    <div className='col-12 harvest-heading'>
                                                        <h2 className="text-white">Withdraw HNTR-BUSD-LP Tokens</h2>
                                                    </div>
                                                    <div className='col-12'>
                                                        <div className='d-flex'>
                                                            <h5 className='available-HNTR' > Available: <span className="popAmtcolor">{withdrawAmt}</span> HNTR-BUSD-LP Tokens</h5>
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6 input-harvest'>
                                                        <div >
                                                            <input type="text" ref={maxWidtrawlAmt} value={myWithdrawNumber} className='form-control' onChange={handleChangeWithdrawl} />
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-6 input-harvest text-left'>
                                                        <div className='maxinput_sec'>
                                                            <h6 className='HNTR-LP text-white' >HNTR-BUSD-LP</h6>
                                                            <button onClick={setWithdraw} className='input-max'>MAX</button>
                                                        </div>
                                                    </div>
                                                    <div >
                                                        <button className='input-confirm' onClick={withdrawLpTokenFunction} type="submit" >Confirm</button>
                                                    </div>
                                                </div>
                                            </>}
                                            handleClose={togglePoolPopup}
                                        />}
                                    </div>
                                    ) : ('')}
                                </div>
                                
                                <div className='card pool1-card  pool_custom_box'>
                                    <div className='card-body pools-card'>
                                        <div className='card-icon'>
                                            <img src={hunterlogo} className="hunter-Logo-img" alt="" />
                                        </div>
                                        <h5 className="card-title"> <span className="pricecolor">{TotaldepositAmt}</span> </h5>
                                        <p className="card-text"> HNTR-BUSD-LP Token Staked </p>
                                    </div>
                                    {address ? (
                                        <div className='card-bottom text-center mb-4'>
                                            <div className='approve-lp card-text'>
                                                {/* <button onClick={approveLpTokenFunction} className='btn-DA-theme approve_deposit_btn approve-lp m-1'>Approve </button> */}
                                                <button onClick={approveLpTokenFunction} disabled className='btn-DA-theme approve_deposit_btn approve-lp m-1'>Approve </button>
                                            </div>
                                            <div className='deposit-div'>
                                                <button disabled className='btn-DA-theme approve_deposit_btn deposit-lp m-1'>Stake</button>
                                                {/* <button onClick={togglePoolPopup1} disabled={depositLpdisable} className='btn-DA-theme approve_deposit_btn deposit-lp m-1'>Stake</button> */}
                                                { isPoolPopup1 && <PoolPopup content={ <>
                                                    <div className='row'>
                                                        <div className='col-12 harvest-heading'>
                                                            <h2 className="text-white">Deposit HNTR-BUSD-LP Tokens</h2>
                                                        </div>
                                                        <div className='col-12'>
                                                            <div className='d-flex'>
                                                                <h5 className='available-HNTR' > Available: <span className="popAmtcolor">{depositAmt}</span> HNTR-BUSD-LP Tokens</h5>
                                                            </div>
                                                        </div>
                                                        <div className='col-sm-6 input-harvest'>
                                                            <div >
                                                                <input type="text" ref={maxDepositAmt} className='form-control' onChange={handleChangeDeposit} value={myDepositNumber} />
                                                            </div>
                                                        </div>
                                                        <div className='col-sm-6 input-harvest text-left'>
                                                            <div className='maxinput_sec'>
                                                                <h6 className='HNTR-LP text-white' >HNTR-BUSD-LP</h6>
                                                                <button onClick={setDeposit} className='input-max'>MAX</button>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <button className='input-confirm' onClick={depositLpTokenFunction} type="submit" disabled={!depositHntrUSD}>Confirm</button>
                                                        </div>
                                                    </div>
                                                    </>}
                                                    handleClose={togglePoolPopup1}
                                                />}
                                            </div>
                                        </div>
                                    ) : (' ')}
                                </div>
                            </div>
                        </div>
                    </div>
                    {alertPopUp}
                </div>
            </div>
        </div>
        {loading ? <Loading /> : ""}
    </>);
}
export default LPStakingPool;