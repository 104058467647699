import React, { useState, useEffect } from "react";
import { useAccount } from 'wagmi'
import Web3 from 'web3';
import hunterlogo from './../assets/hunter-logo.png';
import Web3provider from './../Helpers/Web3Provider';
import { CONFIG } from "../utils/config";

function LPStaking() {
    const Web3Handler = new Web3provider();
    const { address } = useAccount()
    const hunterPancakeURL = CONFIG.HUNTER_PANCAKE;
    const [pendingToken,setPendingToken]=useState(0);
  
    const MINUTE_MS = 3000;
    
    useEffect(() => {
        const interval = setInterval(() => {
            if(address) {
                console.log("user logged in")
                pendingharvestFun();
            }else{
                console.log("user logged out")
                setPendingToken(0);
            }
        }, MINUTE_MS);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address])
    
    function pendingharvestFun(){
        if(address) {
            let postData = { walletAddress: address};
            Web3Handler.checkTokenWidthrawlBalance(postData).then( res => {
                let balance =  Web3.utils.fromWei(res, 'ether');
                if(balance %1 !== 0){
                    balance=parseFloat(balance).toFixed(5);
                }
                if(balance === "0." || balance === 0.){
                    setPendingToken(0);
                }else{
                    setPendingToken(balance);
                }
            }).catch(err=>{
                setPendingToken(0);
            });
        }else{
            setPendingToken(0); 
        }
    }
    
    return (<>
        <div className='container'>
            <div className='staking-content-div'>
                <div className='hntr-boxed'>
                    <div className="content-h2 mt-4">
                        <img src={hunterlogo} className="hunter-Logo-img" alt="" />
                    </div>
                    <h2 className="content-h2 mt-4 text-uppercase font_900">Hunter Rewards Are Live</h2>
                    <p className="content-p mb-4">Stake HNTR-BUSD-LP tokens to farm HNTR rewards!</p>
                    <div className='content-div  mt-2'> 
                        <div className='card-container'>
                            <div className="staking_group card-group">
                                <div className="staking_block card">
                                    <div className='staking_body card-body card-align'>
                                        <div>
                                            <img src={ hunterlogo } className='hunter-Logo-img-small' alt=""/>
                                        </div>
                                        <p className='card-text card-align p-1'> Pending Rewards </p>
                                        <p className='card-text card-align p-1'> <span class="pricecolor">{pendingToken}</span> HNTR </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='content-div mt-4'> 
                        <div className='card-container'>
                            <a href={hunterPancakeURL} rel="noopener noreferrer" target="_blank" className='token-btn pancakelink'>Get HNTR-BUSD-LP Tokens</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}
export default LPStaking;