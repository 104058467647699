import Footer from './includes/footer';
import Header from './includes/header';
import NFTSale from './pages/nftsale';
import ClaimPage from './pages/claimPage';
import LPStaking from './pages/LPStaking';
import LPStakingPool from './pages/LPStakingPool';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { NotificationContainer } from "react-notifications";
import 'react-notifications/lib/notifications.css';

import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'

// Live/Prodcution
import { bsc } from 'wagmi/chains'
const chains = [bsc]

// Sandbox/TestNet
// import { bscTestnet } from 'wagmi/chains'
// const chains = [bscTestnet]



const projectId = '70e705a4200fa74792eb1c4ac092fe7f'

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient
})
const ethereumClient = new EthereumClient(wagmiConfig, chains)

function App() {
  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <Router>
          <Header />
          <Routes>
            {/* Claim Portals */}
            <Route path="/claim" element={<ClaimPage />} />
            <Route path="/claim/:partnerInvestor" element={<ClaimPage />} />

            {/* Staking Portals */}
            <Route path="/lpstaking/pool" element={<LPStakingPool />} />
            <Route path="/lpstaking" element={<LPStaking />} />

            {/* NFT Portals */}
            <Route exact path='/' element={< NFTSale />}></Route>
            <Route path="/nftsale" element={<NFTSale />} />
            <Route path="/:pre_sale_tierID" element={<NFTSale />} />

          </Routes>
          <NotificationContainer />
          <Footer />
        </Router>
      </WagmiConfig>
      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
    </>
  );
}

export default App;
