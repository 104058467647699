import React, { useState, useEffect } from "react";
import { useAccount, useNetwork } from 'wagmi'
import Web3 from 'web3'; 
import Loading from "../includes/loader";
import desktopBanner from './../assets/Images/nftsaleBanner.jpg';
import DALogo from './../assets/Images/DALogo.png';
import NftPlacholder from './../assets/Images/Dogtag_v1_image.png';
import NftSoldPlacholder from './../assets/Images/Dogtag_v1_image_sold.png';
import hunterlogo from './../assets/hunter-logo.png';
import Web3provider from './../Helpers/Web3Provider';
import api from "./../utils/api";
import SweetAlert from 'react-bootstrap-sweetalert';

import errorImg from './../assets/error.png';
import defaultImg from './../assets/default.png';
import processingImg from './../assets/process.png';
import successImg from './../assets/success.png';

function NFTSale() {
  const Web3Handler = new Web3provider();
  const [isWallatConnected, setisWallatConnected] = useState();
  const [loading, setLoading] = useState(false);
  const [productOptions, setproductOptions] = useState([]);
  const [maxperWallet, setmaxperWallet] = useState(0);
  const [nftPrice, setnftPrice] = useState("-");
  const [allQty, setAllQty] = useState("-");
  const [allSoldQty, setAllSoldQty] = useState("-");
  const [currQty, setCurrQty] = useState(1);
  const [maxQty, setMaxQty] = useState(1);
  const [saleDisable, setSaleDisable] = useState();
  const { address } = useAccount()
  const { chain } = useNetwork()

  const [ selectedChain, setSelectedChain] = useState(false);
  const [userbalance, setUserBalance] = useState(0);
  const [alertPopUp, setalertPopUp] = useState(false);

  /************ Buy NFT Popu Starts ********** */
  const [isShowPopup, setisShowPopup] = useState(false);
  const [ClosePopupDisabled, setClosePopupDisabled] = useState(true);
  const [showErrorMessage, setshowErrorMessage] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [ishasApproval] = useState(false);
  const [isApprovePopupImage, setisApprovePopupImage] = useState(defaultImg);
  const [isMintPopupImage, setisMintPopupImage] = useState(defaultImg);
  const [saleActive, setSaleActive] = useState(false);

  const [approvePopupClass, setapprovePopupClass] = useState("");
  const [mintPopupClass, setmintPopupClass] = useState("");


  useEffect(() => {
    async function checkConnection() {
      if (address) {
        setSelectedChain(chain.id)
        setisWallatConnected(true);
      }
    }
    checkConnection();
    call_functions_onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, chain]);

  const MINUTE_MS = 5000;
  useEffect(() => {
    const interval = setInterval(() => {
      get_nft_quantity();
    }, MINUTE_MS);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function closePopup() {
    setisShowPopup(false);
    window.location.reload();
  }
  function stop_loader() {
    setapprovePopupClass("");
    setmintPopupClass("");
    setClosePopupDisabled(false);
  }

  function start_loader() {
    setisShowPopup(true);
    setClosePopupDisabled(true);
  }

  function call_functions_onLoad() {
    get_nft_quantity();
    get_product_List();
    get_max_nft_wallet_qty();
    get_userbalance();
  }

  async function get_product_List() {
    await api.get(`admin/getJsonProductdata`).then(async (response) => {
      const jsonData = response.data.data;
      let resjsonData = JSON.parse(jsonData);
      setproductOptions(resjsonData);
    }).catch((err) => {
      console.log("Error in fetching product List")
    })
  }
    

  function get_max_qty() { 
    if (address) {
      Web3Handler.getMaxNFTPerWallet(address).then(nftQty => {
        Web3Handler.checkmintNFTOfUsers(address).then(res => {
          let qty = parseInt(nftQty) - parseInt(res);
          setMaxQty(qty);
        }).catch(err => { 
          console.log("Error in fetching no of NFT Minted", err)
        })
      }).catch(err => {
        console.log("Error in fetching Max per wallet", err)
      });
    }
  }

  function get_nft_quantity() {
    Web3Handler.getNFTAllQty().then(qty => {
      setAllQty(parseInt(qty));
      Web3Handler.getSalesNFTQty().then(soldqty => {
        setAllSoldQty(parseInt(soldqty));
        Web3Handler.getCategoryData().then(result => {
          Web3Handler.getCategorySoldNFTCount().then(sold_qty => {
            /******** Current Category Sold ******************/
            let get_allSold = parseInt(sold_qty);
            /******** Current Category Total ******************/
            let get_CategoryTotal = parseInt(result[1]);

            let balance = Web3.utils.fromWei(result[3], 'ether');
            setSaleActive(result[2]);
            setnftPrice(balance);
            get_max_qty();
            if ((get_CategoryTotal - get_allSold) > 0) {
              setSaleDisable(false);
            } else {
              setSaleDisable(true);
            }
          }).catch(err => {
            console.log("Error in fetching Category Sold")
          });
        }).catch(err => {
          console.log("Error in fetching Category Info", err)
        });
      }).catch(err => {
        console.log("Error in fetching Sold NFTs")
      });
    }).catch(err => {
      console.log("Error in fetching All NFTs", err) 
    });
  }
  function get_max_nft_wallet_qty() {
    Web3Handler.getMaxNFTPerWallet().then(qty => {
        setmaxperWallet(qty);
    }).catch(err => {
      setLoading(false)
    }); 
  }
  function get_userbalance() {
    if(address){
      Web3Handler.getAccountBalance(address).then(balance => {
        setUserBalance(balance);
      }).catch(err => {
        setLoading(false)
      });
    }
  }
  function funSuccessPopup(msg, msgnexline = "") {
    return (<SweetAlert title="Success" onConfirm={() => funhideSuccessPopup()}>
        {msg}<br></br>{msgnexline}
    </SweetAlert>);
  }
  function funErrorPopup(msg, msgnexline = "") {
      return (<SweetAlert title="Error" onConfirm={() => funhideErrorFailure()}>
          {msg}<br></br>{msgnexline}
      </SweetAlert>);
  }
  function funhideSuccessPopup() {
      setalertPopUp("");
      window.location.reload();
  }

  function funhideErrorFailure() {
      setalertPopUp("");
  }



  async function buy_nft() {
    if (address) {
      Web3Handler.checkSelectedChain(selectedChain).then(chainRes => {
        if(chainRes){
          start_loader();
          setapprovePopupClass("");
          setmintPopupClass("");
          setisApprovePopupImage(defaultImg);
          if (ishasApproval) {
            setisMintPopupImage(defaultImg);
          }
          Web3Handler.checkmintNFTOfUsers(address).then(res => {
            if (parseInt(res) >= parseInt(maxperWallet)) {
              setisApprovePopupImage(errorImg);
              setisMintPopupImage(errorImg);
              setshowErrorMessage(true);
              setErrorMessage("Limit Reached. Cannot buy more");
              stop_loader();
            } else {
              Web3Handler.getCategoryData().then(collData => {
                if (collData[2] === true) {
                  let amount = Web3.utils.fromWei(collData[3], 'ether');
                  amount = amount * parseInt(currQty);
                  let userbalanceEthers = Web3.utils.fromWei(userbalance, 'ether')
                  if (parseInt(userbalanceEthers) >= parseInt(amount)) {
                    let numberOfTokens = currQty;
                    let postData = { numberOfTokens: numberOfTokens };
                    let postData1 = { amount: amount };
                    setapprovePopupClass("loadingImage");
                    setmintPopupClass("");
                    setisApprovePopupImage(processingImg);
                    Web3Handler.provideAllowance(postData1).then(resp => {  
                      console.log("resprespresp", resp)
                      setisApprovePopupImage(successImg);
                      setapprovePopupClass("");
                      setmintPopupClass("loadingImage");
                      setisMintPopupImage(processingImg);
                      Web3Handler.mintNFTUsers(postData).then(res => {
                        setisMintPopupImage(successImg);
                        stop_loader();
                        setisShowPopup(false);
                        setalertPopUp(funSuccessPopup("NFT Minted Successfully", ""));
                      }).catch(error => {
                        console.log("error in mintNFTUsers", error)
                        setisMintPopupImage(errorImg);
                        setshowErrorMessage(true);
                        setErrorMessage(error?.message);
                        stop_loader();
                      });
                    }).catch(error => {
                      console.log("error in provideAllowance", error)
                      setisApprovePopupImage(errorImg);
                      setshowErrorMessage(true);
                      setErrorMessage(error?.message);
                      stop_loader();
                    });
                  } else {
                    setshowErrorMessage(true);
                    setErrorMessage("Insufficient Hunter Token Account Balance");
                    setisApprovePopupImage(errorImg);
                    setisMintPopupImage(errorImg);
                    stop_loader();
                  }
                } else {
                  setisMintPopupImage(errorImg);
                  setalertPopUp(funErrorPopup("Sale is not Active"));
                  stop_loader();
                }
              }).catch(err2 => {
                setshowErrorMessage(true);
                setisApprovePopupImage(errorImg);
                setErrorMessage("Something went wrong With fetching NFT Status");
                stop_loader();
              })
            }
          }).catch(err => {
            console.log("err ", err)
            setshowErrorMessage(true);
            setErrorMessage("Something went wrong. Please try again Later");
            stop_loader();
          });
        }else{
          setLoading(false)
          setalertPopUp( funErrorPopup("You are on different Chain", "Please switch to BSC."));
        }
      }).catch(err => {
        setLoading(false)
        setalertPopUp( funErrorPopup("You are on different Chain", "Please switch to BSC."));
      });
    } else {
      setshowErrorMessage(true);
      setErrorMessage("Please Login before Minting");
    }
  }
  return (
    <>
      <div className="container-fluid no-margin-padding wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.35s" >
        <div className="row d-flex justify-content-center align-items-center py-5">
          <div className="col-ms-12">
            <section className="collection_banner">
              <img src={desktopBanner} alt="NFT banner" className="desktopBannerClass" />
              <img src={DALogo} alt="DA Logo" className="daLogoClass" />
            </section>
            <section className="collection_info">
              {productOptions.map(poduct => (
                <div key="{poduct}" className="container">
                  <ul className="collection_status mt-5 mb-5">
                    <li><h4>{allSoldQty}/{allQty}</h4><p>Items Minted</p></li>
                    <li><h4>{nftPrice}</h4><p>HNTR</p></li>
                    <li><h4>Live</h4><p>Status</p></li>
                  </ul>
                  <div className="collection_description text-center">
                    <h4 className="hunter-market">{poduct.title}</h4>
                    <p>{poduct.description}</p>
                  </div>
                </div>
              ))}
            </section>
            <section className="collection_list mb-5 pb-5">
              <div className="container">
                <div className="product_sales_box">
                  <div className="mintevent text-center" tabIndex="-1">
                    <div className="start_btn stamintFunctionbtn">Status <span>Live</span> </div>
                    <h4>Public Sale</h4>
                    <div className="da_img mb-3">
                      {saleDisable ? (
                        <img className='nft_img' alt="No_placehoder_image" src={NftSoldPlacholder} width="100%" />
                      ) : (
                        <img className='nft_img' alt="Placeholder_Image" src={NftPlacholder} width="100%" />
                      )}
                    </div>
                    <div className="mintprice">Price <img className='nft_hunter_Logo ' src={hunterlogo} alt="" /> {nftPrice} HNTR</div>
                    <div className="amount">
                      <h5>Select Amount</h5>
                      <p>Maximum {maxperWallet} NFTs per wallet</p>
                      <div className="qt_selector">
                      <button
                        onClick={() => {
                          let mint = currQty - 1;
                          if (mint < 1) mint = 1;
                          if (mint > maxQty) mint = maxQty;
                          if (Number(mint) !== 0) {
                            setCurrQty(Number(mint));
                          }
                        }}
                      > - </button>
                        <input 
                          type="text"
                          pattern="[0-9+-]*"
                          onChange={(e) => {
                            if(isNaN(e.target.value)){
                              e.preventDefault();
                            }else{
                              if (Number(e.target.value) > maxQty) {
                                e.preventDefault();
                              }
                              if (Number(e.target.value) > maxQty) {
                                setCurrQty(Number(maxQty));
                              } else {
                                if (e.target.value === 0 || e.target.value === "0") {
                                  e.preventDefault();
                                } else {
                                  setCurrQty(Number(e.target.value));
                                }
                              } 
                            }
                          }}
                          required=""
                          value={currQty}
                          />
                        <button
                          onClick={() => {
                            let mint = currQty + 1;
                            if (mint < 1) mint = 0;
                            if (mint > maxQty) mint = maxQty;
                            if (Number(mint) !== 0) {
                              setCurrQty(Number(mint));
                            }
                          }}
                        > + </button>
                      </div>
                      <div className="mint_btn mt-4">
                        {isWallatConnected && !saleDisable && saleActive ? 
                          <>
                            <button onClick={() => buy_nft()} className='buy-nft-btn'>Mint </button>
                          </> :<>
                            <button disabled className='buy-nft-btn'>Mint </button>
                          </>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              {alertPopUp}

                {isShowPopup ? (
                    <>
                        <div className="modal__backdrop">
                            <div className="modal__container">
                                <button type="button" disabled={ClosePopupDisabled} onClick={closePopup} className="custom_close">x</button>
                                {showErrorMessage ? (
                                    <div className="row customDisplayPopup">
                                        <span className="error popupText">{ErrorMessage}</span>
                                    </div>
                                ) : ('')}
                                <ul className="minting">
                                    <li>
                                        <p><img src={isApprovePopupImage} className={approvePopupClass} alt="" /></p>1. Approve Payment Token
                                    </li>
                                    <li>
                                        <p><img src={isMintPopupImage} className={mintPopupClass} alt="" /></p>2. Minting NFT
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </>) : ("")}
            </section>
          </div>
        </div>
      </div>
      {loading ? <Loading /> : ""}
    </>
  );
}
export default NFTSale;
