import { CONFIG } from './../utils/config'
import { readContract, prepareWriteContract, writeContract, waitForTransaction } from '@wagmi/core'
import Web3 from 'web3'; 

// const hntrIcon = "https://www.hunter-token.com/wp-content/uploads/2022/10/hunterIcon.png";

const ABI = CONFIG.ABI;
const CONTRACT_ADDRESS = CONFIG.CONTRACT_ADDRESS;

const MINT_ABI = CONFIG.MINT_ABI;
const MINT_CONTRACT_ADDRESS = CONFIG.MINT_CONTRACT_ADDRESS;

const HNTR_ABI = CONFIG.HNTR_ABI;
const HNTR_CONTRACT_ADDRESS = CONFIG.HNTR_CONTRACT_ADDRESS;

const STAKING_ABI = CONFIG.STAKING_ABI;
const STAKING_CONTRACT_ADDRESS = CONFIG.STAKING_CONTRACT_ADDRESS;

const LPTOKEN_ADDRESS = CONFIG.LPTOKEN_ADDRESS;
const LPTOKEN_CONTRACT_ABI = CONFIG.LPTOKEN_ADDRESS_ABI;

const poolId = CONFIG.STAKING_POOL;

const catPoolID = CONFIG.CATEGORY_POOL;

const WALLET_CONNECT_CHAIN_ID = CONFIG.WALLET_CONNECT_CHAIN_ID;

class Web3Provider {

    /**************** Error Handling ***********************/
    getMessageFromResponse(errorData) {
        let response = errorData.toString();
        let errorCode = 500;
        let errorMessage = "Something went wrong. Please try again later";
        if (response?.includes("User denied")) {
            errorCode = 4001
            errorMessage = "User denied transaction signature.";
        }
        if (response?.includes("You already vested tokens")) {
            errorCode = 500
            errorMessage = "No tokens available to claim";
        }
        if (response?.includes("transfer amount exceeds allowance")) {
            errorCode = 500
            errorMessage = "Transfer amount exceeds allowance";
        }
        if (response?.includes("Start Vesting after Pre-Sale ends!")) {
            errorCode = 500
            errorMessage = "Start vesting after sale ends";
        }
        if (response?.includes("gas required exceeds allowance (0)")) {
            errorCode = 500
            errorMessage = "Insufficient Funds";
        }
        if (response?.includes("gtransfer amount exceeds balance")) {
            errorCode = 500
            errorMessage = "Transfer amount exceeds balance";
        }
        if (response?.includes("Your token balance is zero")) {
            errorCode = 500
            errorMessage = "Your token balance is zero";
        }
        if (response?.includes("Over Max category tokens")) {
            errorCode = 500
            errorMessage = "All NFT's Sold Out";
        }
        if (response?.includes(" Not enough tokens.")) {
            errorCode = 500
            errorMessage = "Insufficient Funds";
        }
        if (response?.includes("transfer amount exceeds allowance")) {
            errorCode = 500
            errorMessage = "Transfer amount exceeds allowance";
        }
        if (response?.includes("gas required exceeds allowance")) {
            errorCode = 500
            errorMessage = "Gas required exceeds allowance";
        }

        let ErrorResponse = { code: errorCode, message: errorMessage };
        return ErrorResponse;
    }

    /**************** GetCategory Date ***********************/
    async checkSelectedChain(chainID) {
        try {
            if(chainID === WALLET_CONNECT_CHAIN_ID){
                return true;
            }else{
                return false;
            }
        } catch (err) {
            return false;
        }
    }
    
    /**************** GetCategory Date ***********************/
    async getCategoryData() {
        try {
            let res = await readContract({ address: MINT_CONTRACT_ADDRESS, abi: MINT_ABI, functionName: 'categories', args: [catPoolID] }) 
            return res;
        } catch (err) {
            throw (err);
        }
    }

    /**************** Get NFT Qty Per Wallet ***********************/
    async getMaxNFTPerWallet() {
        try {
            let res = await readContract({ address: MINT_CONTRACT_ADDRESS, abi: MINT_ABI, functionName: 'maxNFTPerWallet' }) 
            return parseInt(res);
        } catch (err) {
            throw (err);
        }
    }
     /**************** Check Mint Status for User ***********************/
     async checkmintNFTOfUsers(address) {
        try {
            let res = await readContract({ address: MINT_CONTRACT_ADDRESS, abi: MINT_ABI, functionName: 'balanceOf', args: [address]  }) 
            return parseInt(res);
        } catch (err) {
            throw (err);
        }
    }
    /**************** Get NFT All Qty  ***********************/
    async getNFTAllQty() {
        try {
            let res = await readContract({ address: MINT_CONTRACT_ADDRESS, abi: MINT_ABI, functionName: 'maxSupply' });
            return parseInt(res);
        } catch (err) {
            console.log(err )
            throw (err);
        }
    }
    /**************** Get NFT Sales Qty  ***********************/
    async getSalesNFTQty() {
        try {
            let res = await readContract({ address: MINT_CONTRACT_ADDRESS, abi: MINT_ABI, functionName: 'totalSupply' });
            return parseInt(res);
        } catch (err) {
            // console.log(err)
            throw (err);
        }
    }

    /**************** Fetch User Wallet Balance ***********************/
    async getAccountBalance(address) {
        try {
            let res = await readContract({ address: HNTR_CONTRACT_ADDRESS, abi: HNTR_ABI, functionName: 'balanceOf', args: [address] })
            return res;
        } catch (err) {
            throw (err);
        }
    }

    /**************** Get NFT Total Count  ***********************/
    async getCategorySoldNFTCount() {
        try {
            let res = await readContract({ address: MINT_CONTRACT_ADDRESS, abi: MINT_ABI, functionName: 'totalTokensMintedPerCategory', args: [catPoolID] })
            return res;
        } catch (err) {
            // console.log(err)
            throw (err);
        }
    }

    /**************** Allow User Access to buy NFT ***********************/
    async provideAllowance(params) {
        let { amount } = params;
        try {
            amount = Web3.utils.toWei(amount.toString(), 'ether');
            let response = await prepareWriteContract({ address: HNTR_CONTRACT_ADDRESS, abi: HNTR_ABI, functionName: 'approve', args: [MINT_CONTRACT_ADDRESS, amount], gas: 1_000_000n })
            console.log("response", response)
            let responseHash = await writeContract(response.request)
            const responseData = await waitForTransaction(responseHash)
            return responseData;
        } catch (error) {
            console.log("error in provideAllowance Web3Provider ", error)
            let ErrorResponse = this.getMessageFromResponse(error);
            throw (ErrorResponse);
        }
    }

    /**************** Buy/Mint NFT  ***********************/
    async mintNFTUsers(params) {
        let { numberOfTokens } = params;
        try {
            let response = await prepareWriteContract({ address: MINT_CONTRACT_ADDRESS, abi: MINT_ABI, functionName: 'mintNFT', args: [numberOfTokens, catPoolID], gas: 1_000_000n })
            let responseHash = await writeContract(response.request)
            const responseData = await waitForTransaction(responseHash)
            return responseData;
        } catch (error) {
            console.log("error in mintNFTUsers", error)
            let ErrorResponse = this.getMessageFromResponse(error);
            throw (ErrorResponse);
        }
    }


    /**************** Checking Vesting Per Month ****************/
    async userVestedTokens(params) {
        let { tierId, allocatedTo } = params;
        try {
            let res = await readContract({ address: CONTRACT_ADDRESS, abi: ABI, functionName: 'userVestedTokensMonth', args: [allocatedTo, tierId] })
            return res;
        } catch (error) {
            console.log("error in userVestedTokens", error)
            let ErrorResponse = this.getMessageFromResponse(error);
            throw (ErrorResponse);
        }
    }

    /**************** Vesting Tokens For User ***********************/
    async vestTokensUser(params) {
        let { tier_id } = params;
        try {
            let response = await prepareWriteContract({ address: CONTRACT_ADDRESS, abi: ABI, functionName: 'vestTokens', args: [tier_id], gas: 1_000_000n })
            let responseHash = await writeContract(response.request)
            const responseData = await waitForTransaction(responseHash)
            return responseData;
        } catch (error) {
            console.log("error in vestTokensUser", error)
            let ErrorResponse = this.getMessageFromResponse(error);
            throw (ErrorResponse);
        }
    }

    /**************** Get LP Token Balance ***********************/
    async checkTokenWidthrawlBalance(params) {
        let { walletAddress } = params;
        try {
            let res = await readContract({ address: STAKING_CONTRACT_ADDRESS, abi: STAKING_ABI, functionName: 'pendingTOKEN', args: [poolId, walletAddress] })
            return res;
        } catch (error) {
            console.log("error in checkTokenWidthrawlBalance", error)
            let ErrorResponse = this.getMessageFromResponse(error);
            throw (ErrorResponse);
        }
    }


    /**************** Checking Balance for LP Token ***********************/
    async getBalanceLPToken(address) {
        try {
            let res = await readContract({ address: LPTOKEN_ADDRESS, abi: LPTOKEN_CONTRACT_ABI, functionName: 'balanceOf', args: [address] })
            return res;
        } catch (error) {
            console.log("error in getBalanceLPToken", error)
            let ErrorResponse = this.getMessageFromResponse(error);
            throw (ErrorResponse);
        }
    }

    /**************** Approve Amount for LP Token ***********************/
    async getApprovalLPToken(params) {
        let { balance } = params;
        try {
            let response = await prepareWriteContract({ address: LPTOKEN_ADDRESS, abi: LPTOKEN_CONTRACT_ABI, functionName: 'approve', args: [STAKING_CONTRACT_ADDRESS, balance], gas: 1_000_000n })
            let responseHash = await writeContract(response.request)
            const responseData = await waitForTransaction(responseHash)
            return responseData;
        } catch (error) {
            console.log("error in getApprovalLPToken", error)
            let ErrorResponse = this.getMessageFromResponse(error);
            throw (ErrorResponse);
        }
    }
    
    /**************** Approve Amount for LP Token ***********************/
    async checkAllowanceToken(address) {
        try {
            let res = await readContract({ address: LPTOKEN_ADDRESS, abi: LPTOKEN_CONTRACT_ABI, functionName: 'allowance', args: [address, STAKING_CONTRACT_ADDRESS] })
            return res;
        } catch (error) {
            console.log("error in checkAllowanceToken", error)
            let ErrorResponse = this.getMessageFromResponse(error);
            throw (ErrorResponse);
        }
    }

    /**************** Approve Amount for LP Token ***********************/
    async depositLpToken(params) {
        let { amount } = params;
        let depositamount = Web3.utils.toWei(amount.toString(), 'ether');
        try {
            let response = await prepareWriteContract({ address: STAKING_CONTRACT_ADDRESS, abi: STAKING_ABI, functionName: 'deposit', args: [poolId, depositamount], gas: 1_000_000n })
            let responseHash = await writeContract(response.request)
            const responseData = await waitForTransaction(responseHash)
            return responseData;
        } catch (error) {
            console.log("error in depositLpToken", error)
            let ErrorResponse = this.getMessageFromResponse(error);
            throw (ErrorResponse);
        }
    }

    /**************** Checking Deposite Balance for LP Token ***********************/
    async getdepositeBalanceLPToken(address) {
        try {
            let res = await readContract({ address: STAKING_CONTRACT_ADDRESS, abi: STAKING_ABI, functionName: 'userInfo', args: [poolId, address] })
            return res;
        } catch (error) {
            console.log("error in getdepositeBalanceLPToken", error)
            let ErrorResponse = this.getMessageFromResponse(error);
            throw (ErrorResponse);
        }
    }

    /**************** Checking Reward Balance for LP Token ***********************/
    async getEarningBalanceRewardsToken(address) {
        try {
            let res = await readContract({ address: STAKING_CONTRACT_ADDRESS, abi: STAKING_ABI, functionName: 'pendingTOKEN', args: [poolId, address] })
            return res;
        } catch (error) {
            console.log("error in getEarningBalanceRewardsToken", error)
            let ErrorResponse = this.getMessageFromResponse(error);
            throw (ErrorResponse);
        }
    }

    /**************** Checking Reward Balance for LP Token ***********************/
    async withdrawLpToken(params) {
        let { amount } = params;
        let depositamount = Web3.utils.toWei(amount.toString(), 'ether');
        try {
            let response = await prepareWriteContract({ address: STAKING_CONTRACT_ADDRESS, abi: STAKING_ABI, functionName: 'withdraw', args: [poolId, depositamount], gas: 1_000_000n })
            let responseHash = await writeContract(response.request)
            const responseData = await waitForTransaction(responseHash)
            return responseData;
        } catch (error) {
            console.log("error in withdrawLpToken", error)
            let ErrorResponse = this.getMessageFromResponse(error);
            throw (ErrorResponse);
        }
    }
    
    /**************** Check if User has Allowance LPToken ***********************/
    async checkApprovalLPToken(address) {
        try {
            let res = await readContract({ address: LPTOKEN_ADDRESS, abi: LPTOKEN_CONTRACT_ABI, functionName: 'allowance', args: [address, STAKING_CONTRACT_ADDRESS] })
            return res;
        } catch (error) {
            console.log("error in checkApprovalLPToken", error)
            let ErrorResponse = this.getMessageFromResponse(error);
            throw (ErrorResponse);
        }
    }



    /**************** Import token to user wallet  ***********************/
    async importVestingToken() {
        try {
            // const success = useToken({
            //     address: HNTR_CONTRACT_ADDRESS,
            //     onSuccess(data) {
            //       console.log('Success', data)
            //     },
            // })
            // if (success) {
            //     return true;
            // } else {
            //     return false;
            // }
        } catch (error) {
            console.log("error in importVestingToken", error)
            let ErrorResponse = this.getMessageFromResponse(error);
            throw (ErrorResponse);
        }
    }

}
export default Web3Provider;