import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAccount, useNetwork } from 'wagmi'
import Web3 from 'web3'; 
import Loading from "../includes/loader";
import hunterlogo from './../assets/hunter-logo.png';
import api from "./../utils/api";
import Web3provider from './../Helpers/Web3Provider';
import SweetAlert from 'react-bootstrap-sweetalert';

function ClaimPage() {
  let { partnerInvestor } = useParams();
  const Web3Handler = new Web3provider();
  const { address } = useAccount()
  const { chain } = useNetwork()
  const [ selectedChain, setSelectedChain] = useState(false);
  
  const [loading, setLoading] = useState(false);
  const [alertPopUp, setalertPopUp] = useState(false);
  const [tierOptions, setTierOptions] = useState([])
  const [tierOptionsPartner, setTierOptionsPartner] = useState([])
  const [selTier, setTier] = useState(0);
  const [showOwnedCountBox, setShowOwnedCountBox] = useState(false)
  const [ownedCount, setOwnedCount] = useState(0);
  const [showPresaleWhiteList, setshowPresaleWhiteList] = useState(true);
  const [showPartnerList, setshowPartnerList] = useState(false);

  const [showToken, setShowToken] = useState(false);
  
  console.log("test123")

  
  useEffect(() => {
    async function checkConnectionClaim() {
      if(chain?.id){
        setSelectedChain(chain.id)
      }
      if (partnerInvestor === "partners" || partnerInvestor === "Partners") {
        setshowPartnerList(true);
        setshowPresaleWhiteList(false);
      }
      if (partnerInvestor === "investors" || partnerInvestor === "Investors") {
        setshowPresaleWhiteList(false);
        setshowPartnerList(false);
      }
      let res1 = await createSelectItems();
      setTierOptions(res1);
      let res2 = await createSelectItemsPartner();
      setTierOptionsPartner(res2);
    }
    checkConnectionClaim();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, partnerInvestor, showPartnerList, showPresaleWhiteList, chain]);


  function funSuccessPopup(msg, msgnexline = "") {
    return (<SweetAlert title="Success" onConfirm={() => funhideSuccessPopup()}>
      {msg}<br></br>{msgnexline}
    </SweetAlert>);
  }
  function funSuccessPopupClaim(msg, msgnexline = "") {
    return (<SweetAlert title="Success" onConfirm={() => funhideSuccessPopup()}>
      {msg}<br></br>Import HNTR token address in your wallet to see the tokens
    </SweetAlert>);
  }
  function funEmptyPopup(msg) {
    return (<SweetAlert title="Error" onConfirm={() => funhideEmptyPopup()}>
      {msg}
    </SweetAlert>);
  }
  function funErrorPopup(errrorMsg, msgnexline = "") {
    return (<SweetAlert title="Error" onConfirm={() => funhideErrorFailure()}>
      {errrorMsg}<br></br>{msgnexline}
    </SweetAlert>);
  }
  function funhideSuccessPopup() {
    setalertPopUp("");
  }
  function funhideEmptyPopup() {
    setalertPopUp("");
  }
  function funhideErrorFailure() {
    setalertPopUp("");
  }
  

  async function createSelectItems() {
    if (!showPresaleWhiteList && !showPartnerList) {
      setLoading(true);
      let items = [];
      let seedsArray = ["seed", "pre-seed", "pre-sale-whitelist-2"];
      await api.get(`admin/getJsondata`).then(async (response) => {
        const jsonData = response.data.data;
        let resjsonData = JSON.parse(jsonData);
        for (var i = 0; i < resjsonData.length; i++) {
          if (seedsArray.includes(resjsonData[i].name)) {
            items.push(<option key={resjsonData[i].id} value={resjsonData[i].id}>{resjsonData[i].name}</option>);
          }
        }
        setTierOptions(items);
        return items
      }).catch((error) => {
        console.log("error in createSelectItemsPartner", error)
      });
      setLoading(false);
      return items;
    }
  }
  async function createSelectItemsPartner() {
    // console.log("showPresaleWhiteList ", showPresaleWhiteList, showPartnerList)
    if (!showPresaleWhiteList && showPartnerList) {
      setLoading(true);
      let itemspartner = [];
      let partnerArray = ["seed", "pre-seed", "pre-sale-whitelist", "pre-sale-whitelist-2"];
      await api.get(`admin/getJsondata`).then(async (response) => {
        const jsonData = response.data.data;
        let resjsonData = JSON.parse(jsonData);
        for (var i = 0; i < resjsonData.length; i++) {
          if (!partnerArray.includes(resjsonData[i].name)) {
            itemspartner.push(<option key={resjsonData[i].id} value={resjsonData[i].id}>{resjsonData[i].name}</option>);
          }
        }
        setTierOptionsPartner(itemspartner);
        return itemspartner;
      }).catch((error) => {
        console.log("error in createSelectItemsPartner", error)
      });
      setLoading(false);
      return itemspartner;
    }
  }

  async function checkTierSalesFunction(setTier = 0) {
    if (address) {
      if (setTier === "" || setTier === undefined || setTier === 0 || setTier === "0") {
        setShowOwnedCountBox(false);
        setOwnedCount(0);
      } else {
        let postData = { allocatedTo: address, tierId: setTier };
        Web3Handler.userOwnedTokens(postData).then(res => {
          if (res === 0 || res === undefined) {
            setShowOwnedCountBox(false);
            setOwnedCount(0);
          } else {
            setShowOwnedCountBox(true);
            setOwnedCount(Web3.utils.fromWei(res, "ether"));
          }
        }).catch(error => {
          console.log("error in checkTierSalesFunction", error)
        });
      }
    }
  }
  async function check_status_pre_sale() {
    if (address) {
      setLoading(true)
      Web3Handler.checkSelectedChain(selectedChain).then(chainRes => {
        if(chainRes){
          let postData = { allocatedTo: address, tierId: 1 };
          Web3Handler.userVestedTokens(postData).then(res => {
            if (parseInt(res) !== 0) {
              let claimed_token = parseInt(res) - 1;
              setLoading(false)
              setalertPopUp(funSuccessPopup("PTR+" + claimed_token + " Claimed"));
            } else {
              setLoading(false)
              setalertPopUp(funSuccessPopup("Tokens Not Claimed"));
            }
          }).catch(err => {
            setLoading(false)
            setalertPopUp(funErrorPopup("Error " + JSON.stringify(err)));
          });
        }else{
          setLoading(false)
          setalertPopUp( funErrorPopup("You are on different Chain", "Please switch to BSC."));
        }
      }).catch(err => {
        setLoading(false)
        setalertPopUp( funErrorPopup("You are on different Chain", "Please switch to BSC."));
      });
    }
  }

  async function claim_rewards_pre_sale() {
    if (address) {
      setLoading(true)
      Web3Handler.checkSelectedChain(selectedChain).then(chainRes => {
        if(chainRes){
          let postData = { tier_id: 1 };
          Web3Handler.vestTokensUser(postData).then(res => {
            setLoading(false)
            setalertPopUp(funSuccessPopupClaim("Tokens Claimed Successfully"));
          }).catch(error => {
            setLoading(false)
            setalertPopUp(funErrorPopup(error?.message, ""));
          });
        }else{
          setLoading(false)
          setalertPopUp( funErrorPopup("You are on different Chain", "Please switch to BSC."));
        }
      }).catch(err => {
        setLoading(false)
        setalertPopUp( funErrorPopup("You are on different Chain", "Please switch to BSC."));
      });
    }
  }

  async function claim_rewards() {
    if (address) {
      setLoading(true)
      Web3Handler.checkSelectedChain(selectedChain).then(chainRes => {
        if(chainRes){
          if (selTier === 0 || selTier === "0") {
            setLoading(false);
            setalertPopUp(funEmptyPopup("Please select Tier First"));
          } else {
            let postData = { tier_id: selTier };
            Web3Handler.vestTokensUser(postData).then(res => {
              setLoading(false);
              setalertPopUp(funSuccessPopupClaim("Tokens Claimed Successfully"));
            }).catch(err => {
              setLoading(false);
              setalertPopUp(funErrorPopup(err?.message, ""));
            });
          }
        }else{
          setLoading(false)
          setalertPopUp( funErrorPopup("You are on different Chain", "Please switch to BSC."));
        }
      }).catch(err => {
        setLoading(false)
        setalertPopUp( funErrorPopup("You are on different Chain", "Please switch to BSC."));
      });
    }  
  }

  async function importToken() {
    // if (address) {
    //   setLoading(true)
    //   Web3Handler.importVestingToken().then(tokenData => {
    //     setLoading(false)
    //     setalertPopUp(funSuccessPopup("Token imported Successfully."));
    //   }).catch(error => {
    //     setLoading(false)
    //     setalertPopUp(funErrorPopup(error?.message));
    //   });
    // }
  }

  
  return (
    <>
      <div className='container'>
        <div className='claim-content-div'>
          <div className='hntr-boxed'>
            <div className="content-h2 mt-4">
              <img src={hunterlogo} className="hunter-Logo-img" alt="" />
            </div>
            <h2 className="content-h2 mt-4 text-uppercase font_900">Claim Tokens </h2>
            <p className="content-p mb-4"> Tokens Released As Per Vesting Schedule</p>
            {showOwnedCountBox ? <>
              <p className="content-p mb-4" style={{ marginTop: "-15px" }}>Total Allocation : {ownedCount}</p>
            </> : ""}

            {showPresaleWhiteList && !showPartnerList ? (
              <>
                {address ? (
                  <p className="content-p mb-4" style={{ marginTop: "-15px" }}>Total Allocation : {ownedCount}</p>
                ) : (" ")}
                <div className="tier_group card-group">
                  <div className="tier_block card">
                    <div className="tier_body card-body">
                      <div>
                        <img src={hunterlogo} className="hunter-Logo-img-small" alt="" />
                      </div>
                      <p className="tier_heading card-text">Pre-sale WhiteList</p>
                    </div>
                    {address ? (
                      <div className='card-bottom'>
                        <button onClick={check_status_pre_sale} className='btn btn-secondary tier_token-btn status_btn custom-btn' > Check Status </button>
                        <button onClick={claim_rewards_pre_sale} className='btn btn-secondary tier_token-btn claim_btn custom-btn'> Claim Token</button>
                      </div>
                    ) : (
                      <div className='card-bottom'></div>
                    )}
                  </div>
                  {/* {address ? (
                    <div className='card-bottom mt-4 importtext'>
                      Add token address to your wallet by clicking <a href="#" onClick={importToken} className="importLink"> here</a>
                    </div>
                    ) : ( " " )
                  } */}
                </div>
              </>) : ("")}
            {showPartnerList && !showPresaleWhiteList ? (
              <>
                <div className="tier_group card-group">
                  <div className="tier_block card">
                    <div className="tier_body card-body">
                      <div>
                        <img src={hunterlogo} className="hunter-Logo-img-small" alt="" />
                      </div>
                      <p className="tier_heading card-text">Select Tier to Claim</p>
                    </div>
                    <div className="card-bottom custombtn">
                      <select className="form-elements tier" id="sel_tier" name="sel_tier" onChange={(e) => { 
                        setTier(e.target.value); 
                        checkTierSalesFunction(e.target.value); 
                      }} >
                        <option value="0"  > Select Tier </option>
                        {tierOptionsPartner}
                      </select>
                    </div>
                    {address ? (
                      <div className='card-bottom custombtn'>
                        <button onClick={claim_rewards} className='tier_token-btn claim_btn custom-btn'> Claim Token</button>
                      </div>
                    ) : (
                      <div className='card-bottom'></div>
                    )}
                  </div> 
                  {/* {address ? (
                    <div className='card-bottom mt-4 importtext'>
                      Add token address to your wallet by clicking <a href onClick={importToken} className="importLink"> here</a>
                    </div>) : ( " " ) }  */}
                </div>
              </>):
              (<></>)
            }
            
            { !showPresaleWhiteList && !showPartnerList ? (
              <>
                <div className="tier_group card-group">
                  <div className="tier_block card">
                    <div className="tier_body card-body">
                      <div>
                        <img src={hunterlogo} className="hunter-Logo-img-small" alt="" />
                      </div>
                      <p className="tier_heading card-text">Select Tier to Claim</p>
                    </div>
                    <div className="card-bottom">
                      <select className="form-elements tier" id="sel_tier" name="sel_tier" onChange={(e) => { 
                        setTier(e.target.value); 
                        checkTierSalesFunction(e.target.value); 
                      }} >
                        <option value="0"  > Select Tier </option>
                        {tierOptions}
                      </select>
                    </div>
                    {address ? (
                      <div className='card-bottom custombtn'>
                        <button onClick={claim_rewards} className='tier_token-btn claim_btn custom-btn'> Claim Token</button>
                      </div>
                    ) : (
                      <div className='card-bottom'></div>
                    )}
                  </div>
                  {/* {address ? (
                    <div className='card-bottom mt-4 importtext'>
                      Add token address to your wallet by clicking <a href onClick={importToken} className="importLink"> here</a>
                    </div>) : ( " " ) }  */}
                </div>
              </>):
              (<></>)
            }
          </div>
          {alertPopUp}

          

          {/*----------------------- div end -------------------------------------------  */}
        </div>
      </div>
      <div>
    </div>
      {loading ? <Loading /> : ""}
    </>
  );
}
export default ClaimPage;