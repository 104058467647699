function Footer() {
  return (
    <>
      <footer className="cs-footer cs-dark text-center">      
      </footer>
    </>
  );
}

export default Footer;
